import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import ImageEdit from "./ImageEdit";
import { AppContextProvider } from "./context/AppContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AppContextProvider>
      <Routes>
        <Route path="/" element={<ImageEdit />}>
          <Route path=":blob" element={<ImageEdit />} />
        </Route>
        <Route path="/ImageEdit" element={<App />}>
          <Route path=":blob" element={<App />} />
        </Route>
      </Routes>
    </AppContextProvider>
  </BrowserRouter>
);
