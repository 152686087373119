import "./App.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useEffect, useRef, useState, useContext } from "react";
import uncropped from "./uncropped.png";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import { AppContext } from "./context/AppContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { db } from "./firebase";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

function App() {
  const [crop, setCrop] = useState();
  const [state, dispatch] = useContext(AppContext);
  const [completedCrop, setCompletedCrop] = useState();
  const [brandImage, setBrandImage] = useState(
    "https://cdn.dribbble.com/users/583436/screenshots/1906332/media/8706345ad42d753acf199571e6bef9b5.gif"
  );
  const [siteLink, setSiteLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const previewCanvasRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const imgRef = useRef(null);
  const { blob } = useParams();
  const db = getFirestore();
  const dibbble =
    "https://cdn.dribbble.com/users/583436/screenshots/1906332/media/8706345ad42d753acf199571e6bef9b5.gif";
  console.log({ blob });
  let navigate = useNavigate();
  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        dispatch({
          type: "PREVIEW_CHANGE",
          payload: {
            completedCrop,
            scale,
            rotate,
            newImage: brandImage,
            siteLink,
          },
        });
        handleUpdatingDox();
        navigate(`/${blob}`);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  const getImageBinary = async (blob) => {
    let userDoc = await getDoc(doc(db, "images", blob));
    if (userDoc?.data()?.base64) {
      setBrandImage(userDoc.data().base64);
      setSiteLink(userDoc.data().siteURl);
      setLoading(true);
    }
  };

  const handleUpdatingDox = async () => {
    const userDoc = doc(db, "images", blob);
    await updateDoc(userDoc, {
      completedCrop,
    });
  };
  useEffect(() => {
    setTimeout(() => {
      if (brandImage === dibbble) getImageBinary(blob);
    }, "2000");
  }, [blob, brandImage]);
  return (
    <div className="App">
      {brandImage === dibbble ? (
        <img src={brandImage} alt="uncropped.png" />
      ) : (
        <ReactCrop
          keepSelection={true}
          onComplete={(c) => setCompletedCrop(c)}
          crop={crop}
          onChange={(c) => {
            setLoading(false);
            setCrop(c);
          }}
        >
          <img
            ref={imgRef}
            style={{
              width: "100%",
              height: "100%",
              WebkitFilter: `brightness(${loading ? 0.5 : 1})`,
            }}
            src={brandImage}
            alt="uncropped.png"
          />
        </ReactCrop>
      )}
    </div>
  );
}

export default App;
