import "./App.css";
import "react-image-crop/dist/ReactCrop.css";
import { useEffect, useRef, useState } from "react";
import { canvasPreview } from "./canvasPreview";
import { imgPreview } from "./ImagePreview";
import { AppContext } from "./context/AppContext";
import uncropped from "./uncropped.png";
import { useNavigate, useParams } from "react-router-dom";
import scissors from "./scissors.png";
import globe from "./globe.svg";
import { db } from "./firebase";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useDebounceEffect } from "./useDebounceEffect";
const { ClipboardItem } = window;

function ImageEdit() {
  const db = getFirestore();
  const [fakeState, setFakeState] = useState(null);
  const [brandImage, setBrandImage] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const lollyRef = useRef(null);
  const { blob } = useParams();
  let navigate = useNavigate();

  const handleGettingState = async () => {
    let userDoc = await getDoc(doc(db, "images", blob));
    let info = userDoc.data();
    console.log(info);
    setFakeState({
      completedCrop: info.completedCrop,
      scale: 1,
      rotate: 0,
      newImage: info.base64,
      siteLink: info.siteURl,
    });
    // lollyRef.current.src=info.base64
    // imgRef.current.src=info.base64
    // lollyRef.current.style.width=`${info.completedCrop.width}px`
    // lollyRef.current.style.height=`${info.completedCrop.height}px`
    // lollyRef.current.style.marginLeft=`${info.completedCrop.x}px`
    // lollyRef.current.style.objectPosition=`-${info.completedCrop.x}px -${info.completedCrop.y}px`
    setTimeout(async () => {
      const newImage = await canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        info.completedCrop
      );
      console.log(newImage);

    }, "500");
  };

  useEffect(() => {
    if (blob) handleGettingState();
  }, [blob]);

  useEffect(() => {
    if (fakeState) {
      // We use canvasPreview as it's much faster than imgPreview.
      console.log("here");
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        fakeState.completedCrop
      );
    }
  }, [fakeState]);

  const copyToClipBoard=()=>{
    previewCanvasRef.current.toBlob(function(blob) { 
        const item = new ClipboardItem({ "image/png": blob });
        navigator.clipboard.write([item]); 
    });
  }
  return (
    <div>
      <div className="topnav">
      <div className="leftContNav">
        <img width="40px" src={scissors} />
        <div
          className="copyBubble"
          style={{ display: fakeState?.siteLink ? "flex" : "none" }}
        >
          <div>
            <img width="25px" src={globe} />
          </div>
          <a target="_blank" href={fakeState?.siteLink} rel="noreferrer">
            {fakeState?.siteLink}
          </a>
        </div>
         </div>
        <div className="RightContNav">
            <p onClick={()=>{navigate(`/ImageEdit/${blob}`);}}>Change Crop</p>
            {/* <p onClick={copyToClipBoard}>Copy Image</p> */}
        </div>
      </div>

      <div className="editingArea">
        {/* <img
          ref={lollyRef}
          style={{
            width: fakeState?.completedCrop.width,
            height: fakeState?.completedCrop.height,
            objectFit: 'cover'
          }}
          src={fakeState?.newImage}
        /> */}

        <canvas
          ref={previewCanvasRef}
          style={{
            objectFit: "contain",
            width: fakeState?.completedCrop.width,
            height: fakeState?.completedCrop.height,
          }}
        />
        <div style={{ visibility: "hidden" }}>
          <img
            ref={imgRef}
            style={{ width: "100%", height: "100%" }}
            src={fakeState?.newImage}
            alt="uncropped.png"
          />
        </div>
      </div>
    </div>
  );
}

export default ImageEdit;
